import React, { useEffect } from "react";

function TwitterTimeLine() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
<a 
  class="twitter-timeline"
  data-width="340"
  data-height="500"
  href="https://twitter.com/tanujpuniaINC?ref_src=twsrc%5Etfw">

</a>
`,
      }}
    />
  );
}

export default TwitterTimeLine;
