import React from "react";
import "./OurMissionCard.css";
type ourmissiom_cards_props = {
  logo: string;
  heading: String;
  paragraph: String;
  background?: String;
};
const OurMissionCard = ({
  logo,
  heading,
  paragraph,
  background,
}: ourmissiom_cards_props) => {
  return (
    <div
      className="ourmissioncard"
      style={{ backgroundImage: `url("${background}")`, position: "relative" }}
    >
      <div
        style={{
          content: "",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.5)", // Adjust the alpha value for the desired overlay opacity
          borderRadius: "8px",
        }}
      ></div>
      <img src={logo} alt="logo" />
      <h6>{heading}</h6>
      <p>{paragraph}</p>
    </div>
  );
};

export default OurMissionCard;
