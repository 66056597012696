import React, { Suspense, lazy } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home/Home";
import Navbar from "./components/layout/Navbar/Navbar";
import Footer from "./components/layout/Footer/Footer";
// import About from "./components/pages/About/About";
// import Issues from "./components/pages/Issues/Issues";
// import IssuesSinglePage from "./components/pages/Issues/IssuesSinglePage";
// import Contact from "./components/pages/Contact/Contact";
import GoToTop from "./GoToTop";
// import Media from "./components/pages/Media/Media";
const Media = lazy(() => import("./components/pages/Media/Media"));
const About = lazy(() => import("./components/pages/About/About"));
const Issues = lazy(() => import("./components/pages/Issues/Issues"));
const IssuesSinglePage = lazy(
  () => import("./components/pages/Issues/IssuesSinglePage")
);
const Contact = lazy(() => import("./components/pages/Contact/Contact"));

function App() {
  return (
    <BrowserRouter>
      <GoToTop />
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense
              fallback={
                <div className="loadercss">
                  <div className="flag">
                    <div className="orange"></div>
                    <div className="white">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/a/ad/INC_Logo.png"
                        className="hand"
                      />
                    </div>
                    <div className="green"></div>
                  </div>

                  <div className="flagwave"></div>
                </div>
              }
            >
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <Suspense
              fallback={
                <div className="loadercss">
                  <div className="flag">
                    <div className="orange"></div>
                    <div className="white">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/a/ad/INC_Logo.png"
                        className="hand"
                      />
                    </div>
                    <div className="green"></div>
                  </div>

                  <div className="flagwave"></div>
                </div>
              }
            >
              <About />
            </Suspense>
          }
        />
        <Route
          path="/issues"
          element={
            <Suspense
              fallback={
                <div className="loadercss">
                  <div className="flag">
                    <div className="orange"></div>
                    <div className="white">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/a/ad/INC_Logo.png"
                        className="hand"
                      />
                    </div>
                    <div className="green"></div>
                  </div>

                  <div className="flagwave"></div>
                </div>
              }
            >
              <Issues />
            </Suspense>
          }
        />
        <Route
          path="/contact"
          element={
            <Suspense
              fallback={
                <div className="loadercss">
                  <div className="flag">
                    <div className="orange"></div>
                    <div className="white">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/a/ad/INC_Logo.png"
                        className="hand"
                      />
                    </div>
                    <div className="green"></div>
                  </div>

                  <div className="flagwave"></div>
                </div>
              }
            >
              <Contact />
            </Suspense>
          }
        />

        <Route
          path="/issues/:singlepage"
          element={
            <Suspense
              fallback={
                <div className="loadercss">
                  <div className="flag">
                    <div className="orange"></div>
                    <div className="white">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/a/ad/INC_Logo.png"
                        className="hand"
                      />
                    </div>
                    <div className="green"></div>
                  </div>

                  <div className="flagwave"></div>
                </div>
              }
            >
              <IssuesSinglePage />
            </Suspense>
          }
        />
        <Route
          path="/media"
          element={
            <Suspense
              fallback={
                <div className="loadercss">
                  <div className="flag">
                    <div className="orange"></div>
                    <div className="white">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/a/ad/INC_Logo.png"
                        className="hand"
                      />
                    </div>
                    <div className="green"></div>
                  </div>

                  <div className="flagwave"></div>
                </div>
              }
            >
              <Media />
            </Suspense>
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
