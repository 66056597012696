import React from "react";
import "./HomeBanner.css";
import tanuj from "../../../Assest/Images/Mask group.png";
const HomeBanner = () => {
  return (
    <>
      <div className="overlay"></div>
      <div className="homebanner img">
        <div className="homebanner_left">
          <p>
            <span></span> हम आप मिलकर....
          </p>
          <h1>करते है नए बाराबंकी का निर्माण।</h1>
        </div>
        <div className="homebanner_right">
          <img src={tanuj} alt="owner" />
        </div>
      </div>
    </>
  );
};

export default HomeBanner;
