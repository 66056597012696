import OurMissionCard from "../OurMissionCard/OurMissionCard";
import "./OurMission.css";
import img from "../../../Assest/Images/pngwing 1.png";
import background1 from "../../../Assest/Images/WhatsApp Image 2024-03-03 at 6.31.41 PM.jpeg";
import background2 from "../../../Assest/Images/WhatsApp Image 2024-03-03 at 6.39.54 PM.jpeg";
import background3 from "../../../Assest/Images/WhatsApp Image 2024-03-03 at 6.39.52 PM.jpeg";
import background4 from "../../../Assest/Images/WhatsApp Image 2024-03-03 at 6.38.45 PM.jpeg";
import background5 from "../../../Assest/Images/WhatsApp Image 2024-03-03 at 6.38.45 PM (1).jpeg";
import background6 from "../../../Assest/Images/WhatsApp Image 2024-03-03 at 6.38.44 PM.jpeg";

const OurMission = () => {
  return (
    <div className="ourmissiom">
      <h3>
        हमारी प्राथमिकता
        <span>
          <i> हमारा मिशन</i>
        </span>
      </h3>
      <p className="ourmissiom_p_second">
        देश के कानून और व्यवस्था का पालन करने के साथ-साथ सामंजस्यपूर्ण
        सह-अस्तित्व बनाए रखने और राष्ट्र को आगे ले जाने के लिए एक ज़िम्मेदार
        नागरिक के रूप में अपने कर्तव्यों का पालन करने के लिए बाध्य हूं।
      </p>
      <div className="ourmissiom_cards">
        <OurMissionCard
          logo={img}
          heading={"सामाजिक विकास"}
          paragraph={
            "सामाजिक विकास को मौजूदा व्यवहार,भावनाओं, दूसरों के प्रति दृष्टिकोण और समझ के निरंतर सुधार के रूप में जागरूक करना। समाज की सफलता प्रत्येक नागरिक की भलाई से जुड़ी है।"
          }
          background={background1}
        />
        <OurMissionCard
          logo={img}
          heading={"कृषि विकास"}
          paragraph={
            "किसान सच्चे योद्धा हैं क्योंकि वे हमें भोजन उपलब्ध कराते हैं। किसान देश की आर्थिक प्रगति में महत्वपूर्ण योगदान देते हैं।किसानों के लिए हमारी पहली प्राथमिकता है कि किसानों को लागत के अतिरिक्त 50% एमएसपी की सुविधा मुहैया कराई जाएं।"
          }
          background={background2}
        />
        <OurMissionCard
          logo={img}
          heading={"जिम्मेदारियां और भूमिकाएं"}
          paragraph={
            "समाज के सामान्य हित को लाभ पहुंचाना,दिशा प्रदान करना,लोगों के लिए अच्छा काम करना और ऐसी नीतियां बनाना है जो लोगों की भलाई को बढ़ावा दे जिनकी हम हमेशा सेवा के लिए तत्पर है।"
          }
          background={background3}
        />
        <OurMissionCard
          logo={img}
          heading={"शिक्षा महत्व को बढ़ावा देना"}
          paragraph={
            "शिक्षा मानव विकास की मूलभूत आवश्यकता है। इसलिए हमने ये संकल्प लिया है कि अपने जनपद बाराबंकी में शिक्षा के महत्व को बढ़ाएंगे और अपने वादानुसार कृषि विश्विद्यालय एवम अनुसंधान संस्थान की स्थापना करना,सरकारी मेडिकल कॉलेज व जनपद के हर ब्लॉक में महाविद्यालय की स्थापना करके अपने सपने को साकार करेंगे ।"
          }
          background={background4}
        />
        <OurMissionCard
          logo={img}
          heading={"आर्थिक विकास"}
          paragraph={
            "हमारा लक्ष्य जनपद के सभी लोगों का आर्थिक विकास करना है।जिसमे हमे आलू एवं मेंथा आधारित उद्योग के लिए प्रयास करना है। सूत मिल और बुढ़वल शुगर मिल को पुनः शुरू कराना है।इस तरह आर्थिक विकास का प्रवाह निरन्‍तर चलता रहेगा।"
          }
          background={background5}
        />
        <OurMissionCard
          logo={img}
          heading={"सामाजिक परिवर्तन"}
          paragraph={
            "सामाजिक संरचना वर्तमान संबंधों का एक संयोजन है।  मौजूदा सामाजिक संरचना कई नकारात्मक कारकों और ताकतों से प्रभावित है जो अनिवार्य रूप से इसे बदलने का कारण बनती है। इस प्रकार समाज निरंतर परिवर्तन के अधीन है।"
          }
          background={background6}
        />
      </div>
    </div>
  );
};

export default OurMission;
